.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 50px;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    border: none;
    box-shadow: 0 5px 10px #ccc;
    z-index: 1;
}

.back-to-top:hover {
    color: orangered;
}
