
.form-container {
    padding: 20px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    /* border-style: solid;
    border-radius: 10px;
    border-color: lightgray; */
}

.contact-form-title {
    margin-top: 20px;
}

.contact-form {
    padding: 20px;
    width: 600px;
    margin: auto;
}

input[type="text"], input[type="text"], textarea, select { 
    outline: none;
}

.btn-submit-form {
    background-color: lightgray;
    color: white;
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 5px;
}

.btn-submit-form:hover {
    background-color: orangered;
    color: white;
}

.contact-form-input:focus {
    border-color: orange;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 72, 0, 0.5);
}