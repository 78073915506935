.about-page {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    padding: 4em 16em;
}

.about-page > h1 {
    text-align: center;
    margin-bottom: 2em;
}

.about-section {
    margin-bottom: 2em;
}

.about-section > h3 {
    border-left: 4px solid orange;
    padding-left: 10px;
    margin-bottom: .5em;
}

.about-section > p {
    line-height: 1.5em;
}

.about-section > a, .about-section > a:visited {
    color: orange;
    font-size: 1.2em;
}

.about-section > a:hover {
    color: orangered;
}