:root {
    --black: #2f3234;
    --red: #EA453C;
    --pink: #F47B6E;
    --light-blue: #EFFBFE;
    --blue: #2D31FA;
    --textbox: rgba(201, 178, 159, 0.9);
    --home-background1: rgba(0,0,0,0.1);
    --icon-horizontal-spaceing: 2vw;
}

html, body {
    font-family: 'Oswald', sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;

}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    padding: 0 20px;
    background: linear-gradient(45deg, #F76E11 1.18%, #FFBC80 100%);
    /* background-color: #F76E11; */
    -webkit-box-shadow: 0px 2px 10px -5px rgba(21,6,5,1);
    -moz-box-shadow: 0px 2px 10px -5px rgba(21,6,5,1);
    box-shadow: 0px 2px 10px -5px rgba(21,6,5,1);
}

.header-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-left > h2 {
    text-align: center;
    margin: auto;
    margin-left: 5px;
}


.header-right-link {
    margin-right: 1em;
    font-size: 1.3em;
    font-weight: 400;
}

.header-right-link:hover {
    font-weight: 800;
    text-decoration: underline;
}

a {
    text-decoration: none;
}

a:visited { color: var(--light-blue); }
a:hover { color: var(--light-blue); }
a:active { color: var(--light-blue); }

.product-container {
    margin: 80px 65px;
    padding-bottom: 20px;
    border-bottom: 1px solid;
    border-color: antiquewhite;
}

.product-container > h1 {
    border-left: 6px solid;
    border-color: orange;
    padding-left: 10px;
}

.image-container {
    position: relative;
    height: 300px;
}

.product-image {
    width: 90vw;
    height: 100%;
    object-fit: cover;
}

/* common textbox styles when showing/not showing */
.product-textbox, .image-container:hover .product-textbox {
    position: absolute;
    padding: 10px;
    width: 90vw;
    min-height: 30%;
}

.product-textbox {
 color: transparent;
 bottom: -5px;
 transition: 1s;
}

.image-container:hover .product-textbox {
    display: block;
    bottom: 0;
    color: black;
    background-color: var(--textbox);
}

.favorite {
    color: var(--red);
    font-size: 2.6rem;
    position: absolute;
    top: 15px;
    left: var(--icon-horizontal-spaceing);
    cursor: pointer;
}

.cart {
    color: var(--red);
    font-size: 2.6rem;
    position: absolute;
    top: 60px;
    left: var(--icon-horizontal-spaceing);
    cursor: pointer;
}

.detial-icon {
    color: var(--red) !important;
    font-size: 2.6rem;
    position: absolute;
    top: 15px;
    right: var(--icon-horizontal-spaceing);
    cursor: pointer;
}

.trashbin {
    font-size: 2.2rem;
}

.contact-cards {
    display: flex;
    justify-content: space-around;
    padding: 3em 6em;
    margin: 5px 12em;
    border-top: 2px solid;
    border-color: var(--black);
}

.contact-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
